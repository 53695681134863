import React, { Component } from 'react';
import reactFeature from '../../assets/images/react-feature.svg';
import sassFeature from '../../assets/images/sass-feature.svg';
import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
import responsiveFeature from '../../assets/images/responsive-feature.svg';
import { Card, CardBody, Row, Col, Form,FormGroup,Input, Label, Button} from 'reactstrap';

class Dashboard extends Component {
  state = {
    floatBox: 'display-none',
    numOfPeopleWorking: 0,
    totalTarget: 0,
    targetSfg: 0,
    targetSnt: 0
  }  
   
  render() {
    const heroStyles = {
      padding: '50px 0 70px'
    };


const updateNumberOfPeople = (e)  => {
      console.log('event', e);
      //this.setState({numOfPeopleWorking: event.target.value});
    }
   
const calculate = () => {
  console.log('I am going to calculate:', this.state.numOfPeopleWorking);
  let s_fg= 89;
  let s_nt=79;
  
  let salary=32*7.5;
  
  let totalTarget = Math.round((salary * this.state.numOfPeopleWorking)/0.7, 2);
 
  
  
  this.setState({floatBox:'display-flex', totalTarget: totalTarget,
  targetSfg: Math.ceil(totalTarget/s_fg), targetSnt: Math.ceil(totalTarget/s_nt)
})
}
    return (
      <div>
        <Row>
          <Col md={6}>
            <div className="home-hero" style={heroStyles}>
              <h1>Hello, Best Friends</h1>
              <p className="text-muted">
             Salon Budget
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className="display-flex">
                {/* <img
                  src={reactFeature}
                  style={{ width: 70, height: 70 }}
                  alt="react.js"
                  aria-hidden={true}
                /> */}
                <div className="m-l">
                  <h2 className="h4"> </h2>
                 
          <Label for="exampleText">How many people is working today?</Label>
          <Input type="text" value={this.state.numOfPeopleWorking} name="text" id="workingppl" onChange={(e) => this.setState({numOfPeopleWorking: e.target.value}) }/>
          <Button onClick={() => calculate()}>Calculate</Button>
        
                </div>
              </CardBody>
            </Card>
          </Col>
          
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody className={this.state.floatBox}>
                <img
                  src={sassFeature}
                  style={{ width: 70, height: 70 }}
                  alt="Sass"
                  aria-hidden={true}
                />
                <div className="m-l">
                  <h2 className="h4">Target Number</h2>
                  <p className="text-muted">
                    if you have {this.state.numOfPeopleWorking} people working today</p> 
                    <h2 className="h4"> total Target: {this.state.totalTarget} </h2> 
                    <h2 className="h4">
                         small full grooming: {this.state.targetSfg} </h2>
                        <h2 className="h4">  small nice & tidy: {this.state.targetSnt} 
                        </h2>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody className={this.state.floatBox}>
                <img
                  src={responsiveFeature}
                  style={{ width: 70, height: 70 }}
                  alt="Responsive"
                  aria-hidden={true}
                />
                <div className="m-l">
                  <h2 className="h4">well done</h2>
                  <p className="text-muted">
                    keep it up
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
